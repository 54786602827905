<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <v-snackbar
              top
              v-model="snackbar"
          >
            {{ msg }}
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="pink"
                  text

                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row>
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <v-card class="pa-4" style="border-radius: 20px;" color="#f4f5f8" elevation="10">
            <v-card-title>
              Forgot Password
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="data.email"
                      color="black"
                      label="Email"
                      hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="2">

                </v-col>
                <v-col md="4" sm="8">
                  <v-row>
                    <v-col cols="6">
                      <v-btn style="color:white" color="#fdbd68" @click="cancel()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn style="color:white" color="#39bf88" @click="submit()">Submit</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="4" sm="2">

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </div>
    <div v-else>
      <v-snackbar
          top
          v-model="snackbar"
      >
        {{ msg }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text

              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-row class="mt-4">
        <v-col cols="12">
          <v-btn style="border-radius: 0!important" class="text-capitalize white--text" width="100%" color="#39bf88">
            Forgot Password
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-16">
          <v-text-field
              v-model="data.email"
              style="border-radius: 21px"
              class="mx-8"
              type="email"
              label="Email"
              placeholder="Email"
              required
              outlined
              color="#39bf88"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn @click="submit" height="42" class="white--text" elevation="1" style="border-radius: 10px" color="#39bf88" width="60%">
            submit
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn @click="cancel" height="42" class="white--text" elevation="1" style="border-radius: 10px" color="#fdbd68" width="60%">
            cancel
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data: () => {
    return {
      screenType: null,
      data: [],
      snackbar:false,
      msg:''
    }
  },
  methods:{
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    cancel(){
      this.data=[]
    },
    submit(){
      window.axios.post('api/auth/forgot-password',{
        email:this.data.email,
      }).then(()=>{
        this.snackbar=true
        this.msg="Request Submitted! Please Check your email for the reset link"
      }).catch((err)=>{
        this.snackbar=true
        this.msg=err.response.data.errors.email[0]
      })
    }
  },
  mounted() {
    this.getScreenType()
  }
}
</script>

<style scoped>

</style>